import axios from 'axios';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import { createContext, useMemo, useEffect, useState, useContext } from 'react';
import { useAccount } from 'wagmi';

import config from '../config';
import type { Nft } from '../services/nft/types';

type Props = {
  children: ReactNode;
};

export interface Results {
  results: Result[];
}

export interface Result {
  startAt: number;
  endAt: number;
  winners: Winner[];
}

export interface Winner {
  heedongToken: number;
  heedongTokenMetadata?: Nft;
  address: string;
  amount: number;
}

type FetchContextProps = {
  isLoading: boolean;
  wonResults: Winner | undefined;
  selectedResult: Result;
  showMarquee: boolean;
  fetchResults: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FetchContext = createContext({} as FetchContextProps);

export const FetchProvider: FC<{ children: ReactNode }> = ({
  children,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState<Result[]>([]);
  const { address } = useAccount();
  const { Provider } = FetchContext;

  const router = useRouter();
  const { id } = router.query;
  const fetchResults = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get<Results>(config.gameOfFetch.resultsSrc);

      // add metadata for data winners using metadata api
      const lastElement = data.results.length - 1;
      // eslint-disable-next-line no-await-in-loop
      data.results[lastElement].winners = await Promise.all(
        data.results[lastElement].winners.map(async (winner) => {
          const winnerWithMetadata = winner;
          winnerWithMetadata.heedongTokenMetadata = (
            await axios.get<Nft>(
              `${config.apiUrl}/nft-metadata/heedong/${winner.heedongToken}`
            )
          ).data;
          return winnerWithMetadata;
        })
      );

      setResults(
        data.results
          .filter((result) => result.winners.length > 0)
          .filter((result) => new Date(result.endAt) < new Date())
      );
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBareResults = async () => {
    const { data } = await axios.get<Results>(config.gameOfFetch.resultsSrc);
    setResults(
      data.results
        .filter((result) => result.winners.length > 0)
        .filter((result) => new Date(result.endAt) < new Date())
    );
  };

  const selectedResult = useMemo(() => {
    return id && results[Number(id)]
      ? results[Number(id)]
      : results[results.length - 1];
  }, [id, results]);

  const wonResults = useMemo(
    () => selectedResult?.winners.find((result) => result.address === address),
    [selectedResult, address]
  );

  const showMarquee = useMemo(() => {
    if (!results.length) return false;
    const daysAfterLatestDraw = dayjs(dayjs()).diff(
      results[results.length - 1].endAt,
      'day'
    );
    return daysAfterLatestDraw >= 0 && daysAfterLatestDraw < 3;
  }, [results]);

  useEffect(() => {
    fetchBareResults();
  }, []);

  return (
    <Provider
      value={{
        isLoading,
        wonResults,
        selectedResult,
        showMarquee,
        fetchResults,
      }}
    >
      {children}
    </Provider>
  );
};

export const useFetchContext = () => useContext(FetchContext);
