import packageJson from '../../../package.json';

const config = {
  apiUrl: process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:8080',
  version: packageJson.version,
  contractAddresses: {
    ivyBoys:
      process.env.NEXT_PUBLIC_IVYBOYS_CONTRACT_ADDRESS ||
      '0x809D8f2B12454FC07408d2479cf6DC701ecD5a9f',
    ivyLeague:
      process.env.NEXT_PUBLIC_IVYLEAGUE_CONTRACT_ADDRESS ||
      '0x03C3f46E05835693DC93d6374b6Bb36c979c9ac6',
    heedong:
      process.env.NEXT_PUBLIC_HEEDONG_CONTRACT_ADDRESS ||
      '0x52352040b5262d64973B004d031Bc041720Aa434',
  },
  allowedAddresses: [
    '0xD60b6A740aB7966e51423269C5ba8B84A0EfE7A1',
    '0xD5452f6d8BD097bBa338a31ee238fd9c1216D08B',
    '0x1e689c2EBCdB2C8BDE1d1b36E53bF9A8291Bd086',
    '0x73D5f73D4bFcba36f784de424ff8DE7F17E3A08F',
    '0xC0A68cAee997d544391f267B8fa1E1114D28D8C5',
    '0x61219D19B588F008A9D58DeA7ce0221C0D862E4C',
    '0x67A44fEd99d0336cD681559Cab847410b93FfD57',
    '0xd6A4fFE9b2377B4526c052134EEA72A810E3FF73',
    '0x2d0BDea4fb63a0BbFcFD75b609c627a5Af607B11',
  ],
  bones: {
    firstBonesDrop: '2023-05-02T02:00:00.000Z',
    brigadeOpen: '2023-05-01T14:00:00.000Z',
  },
  gameOfFetch: {
    resultsSrc:
      'https://storage.googleapis.com/bbrc-public/heedong/game-of-fetch/results.json',
  },
  stars: {
    rankUrl:
      'https://storage.googleapis.com/bbrc-public/heedong/star/rank.json',
  },
};

export default config;
