import type React from 'react';

import { BrigadeProvider } from '~/lib/contexts/BrigadeContext';
import { CollectibleProvider } from '~/lib/contexts/CollectibleContext';
import { FeatureFlagProvider } from '~/lib/contexts/FeatureFlagContext';
import { FetchProvider } from '~/lib/contexts/FetchContext';
import { HeeDongNftProvider } from '~/lib/contexts/HeeDongNftContext';
import { IvyNftProvider } from '~/lib/contexts/IvyNftContext';
import { StarProvider } from '~/lib/contexts/StarContext';

export default function AppProviders({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <FeatureFlagProvider>
      <HeeDongNftProvider>
        <FetchProvider>
          <IvyNftProvider>
            <CollectibleProvider>
              <StarProvider>
                <BrigadeProvider>{children}</BrigadeProvider>
              </StarProvider>
            </CollectibleProvider>
          </IvyNftProvider>
        </FetchProvider>
      </HeeDongNftProvider>
    </FeatureFlagProvider>
  );
}
