import type { FC, ReactNode } from 'react';
import React, { useState, useContext } from 'react';
import { useAccount } from 'wagmi';

import config from '@config';
import nftService from '@services/nft/alchemy.service';
import type { Nft } from '@services/nft/types';

type Props = {
  children: ReactNode;
};

type IvyNftContextType = {
  isFetchingIvys: boolean;
  getIvys: () => void;
  ivyBoys: Record<string, Nft>;
  ivyLeague: Record<string, Nft>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const IvyNftContext = React.createContext({} as IvyNftContextType);

export const IvyNftProvider: FC<{ children: ReactNode }> = ({
  children,
}: Props) => {
  const { address } = useAccount();
  const { Provider } = IvyNftContext;
  const [isFetchingIvys, setIsFetchingIvys] = useState<boolean>(false);
  const [ivyBoys, setIvyBoys] = useState<Record<string, Nft>>({});
  const [ivyLeague, setIvyLeague] = useState<Record<string, Nft>>({});

  /**
   * Gets Ivys
   */
  const getIvys = async () => {
    if (address) {
      setIsFetchingIvys(true);
      const ivyBoysNfts = await nftService.getNftsForOwner(
        config.contractAddresses.ivyBoys,
        address
      );
      const ivyLeagueNfts = await nftService.getNftsForOwner(
        config.contractAddresses.ivyLeague,
        address
      );

      setIvyBoys(ivyBoysNfts);
      setIvyLeague(ivyLeagueNfts);
      setIsFetchingIvys(false);
    }
  };

  return (
    <Provider
      value={{
        isFetchingIvys,
        getIvys,
        ivyBoys,
        ivyLeague,
      }}
    >
      {children}
    </Provider>
  );
};

export const useIvyNftContext = () => useContext(IvyNftContext);
