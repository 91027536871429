const constants = {
  walletCollectibleIds: {
    giftBones: 1,
    poopieBones: 2,
  },
  tokenCollectibleIds: {
    magicBones: 1,
    goldenBones: 2,
    rainbowBones: 3,
  },
  boneNameToIdMapper: {
    'Magic Bone': 1,
    'Rainbow Bone': 3,
    'Golden Bone': 2,
  },
  bones: {
    golden: {
      id: 2,
      name: 'Golden Bone',
      description: 'Golden Bone',
      image_url:
        'https://storage.googleapis.com/bbrc-public/heedong/bones/gold.gif',
      animation_url:
        'https://storage.googleapis.com/bbrc-public/heedong/bones/gold.gif',
      quantity: 0,
      stars: 1,
    },
    rainbow: {
      id: 3,
      name: 'Rainbow Bone',
      description: 'Rainbow Bone',
      image_url:
        'https://storage.googleapis.com/bbrc-public/heedong/bones/rainbow.gif',
      animation_url:
        'https://storage.googleapis.com/bbrc-public/heedong/bones/rainbow.gif',
      quantity: 0,
      stars: 3,
    },
    magic: {
      id: 1,
      name: 'Magic Bone',
      description: 'Magic Bone',
      image_url:
        'https://storage.googleapis.com/bbrc-public/heedong/bones/magic.mp4',
      animation_url:
        'https://storage.googleapis.com/bbrc-public/heedong/bones/magic.mp4',
      quantity: 0,
      stars: 10,
    },
  },
  brigade: {
    groupSizeMapper: {
      1: { bone: 'Golden', name: 'Solo' },
      2: { bone: 'Rainbow', name: 'Pair' },
      3: { bone: 'Magic', name: 'League' },
    },
  },
  blindbox: {
    minStakeDays: 69,
    artistsReveal: {
      5: {
        name: 'HeeDong by Aaron Chang',
        reveal: '/blindbox-artists-reveal/AaronChang.mp4',
        pfp: 'https://storage.googleapis.com/bbrc-public/heedong/blindbox/AaronChang.mp4',
      },
      6: {
        name: 'HeeDong x Spirit Dao',
        reveal: '/blindbox-artists-reveal/SpiritDao.mp4',
        pfp: 'https://storage.googleapis.com/bbrc-public/heedong/blindbox/SpiritDAO.mp4',
      },
      7: {
        name: 'HeeDong x MEMELAND',
        reveal: '/blindbox-artists-reveal/Memeland.mp4',
        pfp: 'https://storage.googleapis.com/bbrc-public/heedong/blindbox/Memeland.mp4',
      },
      8: {
        name: 'HeeDong x Pudgy Penguin',
        reveal: '/blindbox-artists-reveal/PudgyPenguin.mp4',
        pfp: 'https://storage.googleapis.com/bbrc-public/heedong/blindbox/Pudgy%20Penguin.mp4',
      },
      9: {
        name: 'HeeDong x SBTG',
        reveal: '/blindbox-artists-reveal/SBTG.mp4',
        pfp: 'https://storage.googleapis.com/bbrc-public/heedong/blindbox/SBTG.mp4',
      },
      10: {
        name: 'HeeDong x TOBYATO',
        reveal: '/blindbox-artists-reveal/Tobyato.mp4',
        pfp: 'https://storage.googleapis.com/bbrc-public/heedong/blindbox/Tobyato.mp4',
      },
      11: {
        name: 'Blindbox (HeeDong x TYC)',
        reveal: '/blindbox-artists-reveal/TYC.mp4',
        pfp: 'https://storage.googleapis.com/bbrc-public/heedong/blindbox/TYC.mp4',
      },
      12: {
        name: 'Blindbox Mystery Box',
        reveal: '/blindbox-artists-reveal/TYC.mp4',
        pfp: 'https://storage.googleapis.com/bbrc-public/heedong/blindbox/Mystery.mp4',
      },
    },
  },
};

export default constants;
