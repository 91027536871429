import type { SendTransactionResult } from '@wagmi/core';
import { readContract, prepareWriteContract, writeContract } from '@wagmi/core';

import HeeDongApiProvider from './heedong-api/heedong-api.provider';
import type { Nft, NftServiceProvider } from './types';

class NftService {
  private NftProvider: NftServiceProvider;

  private nftContractAddress: string;

  constructor() {
    this.NftProvider = HeeDongApiProvider;
    if (process.env.NEXT_PUBLIC_NFT_CONTRACT_ADDRESS) {
      this.nftContractAddress = process.env.NEXT_PUBLIC_NFT_CONTRACT_ADDRESS;
    } else {
      throw Error('No NFT Contract Address set');
    }
  }

  async getNftsForOwner(ownerAddress: string): Promise<Record<string, Nft>> {
    const nfts = await this.NftProvider.getNftsForOwner({
      address: ownerAddress,
      nftAddresses: [this.nftContractAddress],
    });

    const nftsObject: Record<string, Nft> = {};
    nfts.forEach((nft) => {
      nftsObject[nft.tokenId] = {
        ...nft,
        contractAddress: this.nftContractAddress,
      };
    });

    return nftsObject;
  }

  async updateStakingStatus(
    nfts: Record<string, Nft>,
    address: string
  ): Promise<Record<string, Nft>> {
    const nftsObject: Record<string, Nft> = nfts;
    const tokenIds = Object.keys(nftsObject).map((tokenId) => Number(tokenId));
    const stakedStatuses = await this.getNftStakeStatus(tokenIds, address);
    Object.keys(nftsObject).forEach((tokenId, index) => {
      nftsObject[tokenId].stakedFrom = stakedStatuses[index];
    });
    return nftsObject;
  }

  /**
   * Gets the NFT stake status
   * @param tokenId number
   * @returns unix timestamp since it was staked
   */
  async getNftStakeStatus(
    tokenIds: number[],
    address: string
  ): Promise<number[]> {
    const stakedStatuses = await readContract({
      address: this.nftContractAddress as `0x${string}`,
      abi: [
        {
          inputs: [
            {
              internalType: 'uint256[]',
              name: '_tokens',
              type: 'uint256[]',
            },
          ],
          name: 'getUsersStaked',
          outputs: [
            {
              internalType: 'uint256[]',
              name: '_stakedStatus',
              type: 'uint256[]',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ] as const,
      functionName: 'getUsersStaked',
      args: [tokenIds.map(BigInt)],
      account: address as `0x${string}`,
    });

    return stakedStatuses.map((stakedStatus) => Number(stakedStatus));
  }

  /**
   * Stakes a set of tokens
   * @param tokenIds string[] of tokenIds
   * @returns SendTransactionResult
   */
  async stake(tokenIds: string[]): Promise<SendTransactionResult> {
    const config = await prepareWriteContract({
      address: this.nftContractAddress as `0x${string}`,
      abi: [
        {
          inputs: [
            {
              internalType: 'uint256[]',
              name: '_tokenIds',
              type: 'uint256[]',
            },
          ],
          name: 'stake',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      functionName: 'stake',
      args: [tokenIds.map(BigInt)],
    });
    return writeContract(config);
  }

  /**
   * Unstakes a set of tokens
   * @param tokenIds string[] of tokenIds
   * @returns SendTransactionResult
   */
  async unstake(tokenIds: string[]): Promise<SendTransactionResult> {
    const config = await prepareWriteContract({
      address: this.nftContractAddress as `0x${string}`,
      abi: [
        {
          inputs: [
            {
              internalType: 'uint256[]',
              name: '_tokenIds',
              type: 'uint256[]',
            },
          ],
          name: 'unstake',
          outputs: [],
          stateMutability: 'payable',
          type: 'function',
        },
      ],
      functionName: 'unstake',
      args: [tokenIds.map(BigInt)],
      value: BigInt(0),
    });
    return writeContract(config);
  }

  async safeTransferWhileStaked(to: `0x${string}`, tokenIds: number[]) {
    const config = await prepareWriteContract({
      address: this.nftContractAddress as `0x${string}`,
      abi: [
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256[]',
              name: 'tokenIds',
              type: 'uint256[]',
            },
          ],
          name: 'transferWhileStaked',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      functionName: 'transferWhileStaked',
      args: [to, tokenIds.map(BigInt)],
    });
    return writeContract(config);
  }

  async claimVinyl(tokenIds: number[]) {
    const config = await prepareWriteContract({
      address: this.nftContractAddress as `0x${string}`,
      abi: [
        {
          inputs: [
            {
              internalType: 'uint256[]',
              name: '_tokenIds',
              type: 'uint256[]',
            },
          ],
          name: 'claimVinyl',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      functionName: 'claimVinyl',
      args: [tokenIds.map(BigInt)],
    });
    return writeContract(config);
  }
}

export default new NftService();
