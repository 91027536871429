import axios from 'axios';
import dayjs from 'dayjs';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useState, useContext } from 'react';
import { useAccount } from 'wagmi';

import config from '../config';
import constants from '../constants';

import { useCollectibleContext } from './CollectibleContext';

type Props = {
  children: ReactNode;
};

export type StarTier = {
  minimalStarPoints: number;
  name: string;
  minimalRankPercentile: number;
};

export type StarToken = {
  tokenId: number;
  phase: number;
  type: string;
  image: string;
};

export type StarBoneCount = {
  golden: number;
  rainbow: number;
  magic: number;
};

export type StarOwner = {
  address: string | `0x${string}`;
  tokens: StarToken[];
  bones: StarBoneCount;
  starPoints: number;
  rank: number;
  starPower: string;
  osProfile: string;
};

export type StarsRankResponse = {
  lastUpdated: string;
  owners: StarOwner[];
  tiers: StarTier[];
};

type StarContextProps = {
  isLoading: boolean;
  rank: number;
  starPower: string;
  stars: number;
  lastUpdated: string | undefined;
  nextLevel: string;
  starsToNextLevel: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StarContext = createContext({} as StarContextProps);

export const StarProvider: FC<{ children: ReactNode }> = ({
  children,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [stars, setStars] = useState(0);
  const [starOwner, setStarOwner] = useState<StarOwner | undefined>();
  const [starTiers, setStarTiers] = useState<StarTier[]>([]);
  const [nextLevel, setNextLevel] = useState<string>('');
  const [starsToNextLevel, setStarsToNextLevel] = useState<number>(0);
  const [lastUpdated, setLastUpdated] = useState<string | undefined>();
  const { bones, tokenCollectibles } = useCollectibleContext();

  const { address } = useAccount();
  const { Provider } = StarContext;

  const getNextLevel = (_stars: number) => {
    if (starOwner?.starPower) {
      for (let i = 0; i < starTiers.length; i += 1) {
        if (_stars < starTiers[i].minimalStarPoints) {
          return {
            starsToNextLevel: starTiers[i].minimalStarPoints - _stars,
            nextLevel: starTiers[i].name,
          };
        }
        if (i === starTiers.length - 1) {
          return {
            starsToNextLevel: 0,
            nextLevel: 'world-tour',
          };
        }
      }
    }
    return {
      nextLevel: '',
      starsToNextLevel: 0,
    };
  };

  const updateStars = async () => {
    const boneStars = bones.map(
      (bone: { quantity: number; stars: number; id: number }) =>
        bone.quantity *
        (Object.values(constants.bones).find((_bone) => _bone.id === bone.id)
          ?.stars || 0)
    );

    const totalStars: number = boneStars.reduce(
      (partialSum: number, a: number) => partialSum + a,
      0
    );

    setStars(totalStars);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { nextLevel: _nextLevel, starsToNextLevel: _starsToNextLevel } =
      getNextLevel(totalStars);

    setNextLevel(_nextLevel);
    setStarsToNextLevel(_starsToNextLevel > 0 ? _starsToNextLevel : 0);
  };

  const fetchStarsRank = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get<StarsRankResponse>(config.stars.rankUrl);
      data.tiers.sort((a, b) => a.minimalStarPoints - b.minimalStarPoints);

      setStarTiers(
        data.tiers.sort((a, b) => a.minimalStarPoints - b.minimalStarPoints)
      );

      setStarOwner(
        data.owners.find(
          (owner) => owner.address.toLowerCase() === address?.toLowerCase()
        ) as StarOwner | undefined
      );
      setLastUpdated(dayjs(data.lastUpdated).format('DD MMM, HH:mmA ZZ'));
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStarsRank();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    updateStars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenCollectibles, bones]);

  return (
    <Provider
      value={{
        isLoading,
        stars,
        starPower: starOwner?.starPower || 'Newcomer',
        rank: starOwner?.rank || 0,
        nextLevel,
        starsToNextLevel,
        lastUpdated,
      }}
    >
      {children}
    </Provider>
  );
};

export const useStarContext = () => useContext(StarContext);
