import type { FC, ReactNode } from 'react';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useAccount } from 'wagmi';
import { signMessage } from 'wagmi/actions';

import heedongApiProvider from '../services/nft/heedong-api/heedong-api.provider';
import type { Brigade, BrigadeFormation, Nft } from '../services/nft/types';
import { autoGroupBrigades, mapNftToBrigades } from '../utils/brigades';

import { useHeeDongNftContext } from './HeeDongNftContext';
import { useIvyNftContext } from './IvyNftContext';

type Props = {
  children: ReactNode;
};

type BrigadeContextType = {
  brigades: Brigade[];
  newBrigadeFormation: BrigadeFormation;
  setNewBrigadeFormation: React.Dispatch<
    React.SetStateAction<BrigadeFormation>
  >;
  resetNewBrigadeFormation: () => void;
  showNewBrigadeModal: boolean;
  submitBrigade: () => void;
  setExistingBrigadeId: React.Dispatch<React.SetStateAction<number | null>>;
  existingBrigadeId: number | null;
  deleteBrigade: (reset?: boolean) => void;
  showSuccess: boolean;
  setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  autoGroup: () => void;
  autoGroupResults: (Nft | undefined)[][];
  setAutoGroupResults: React.Dispatch<
    React.SetStateAction<(Nft | undefined)[][]>
  >;
  isTokenInBrigade: (token: Nft) => boolean;
  autoGroupingSubmitBrigade: () => Promise<void>;
  gettingSignature: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BrigadeContext = React.createContext({} as BrigadeContextType);

export const BrigadeProvider: FC<{ children: ReactNode }> = ({
  children,
}: Props) => {
  const { address } = useAccount();
  const [brigades, setBrigades] = useState<Brigade[]>([]);
  const { ivyBoys, ivyLeague } = useIvyNftContext();
  const { heedongs } = useHeeDongNftContext();
  const [signature, setSignature] = useState('');
  const { Provider } = BrigadeContext;
  const [isLoading, setIsLoading] = useState(false);
  const [gettingSignature, setGettingSignature] = useState(false);
  const [newBrigadeFormation, setNewBrigadeFormation] =
    useState<BrigadeFormation>({
      heedong: null,
      ivyBoy: null,
      ivyLeague: null,
    });
  const [existingBrigadeId, setExistingBrigadeId] = useState<number | null>(
    null
  ); // used to check if editing brigade
  const [showSuccess, setShowSuccess] = useState(false);
  const [autoGroupResults, setAutoGroupResults] = useState<
    (Nft | undefined)[][]
  >([]);

  const showNewBrigadeModal = useMemo(() => {
    return Object.values(newBrigadeFormation).some((token) => token !== null);
  }, [newBrigadeFormation]);

  const resetNewBrigadeFormation = () => {
    setNewBrigadeFormation({
      heedong: null,
      ivyBoy: null,
      ivyLeague: null,
    });
    setShowSuccess(false);
    setExistingBrigadeId(null);
  };

  useEffect(() => {
    setSignature('');
  }, [address]);

  const getSignature = async () => {
    setGettingSignature(true);
    let signedSignature: string;
    if (!signature) {
      signedSignature = await signMessage({ message: address as string });
      setSignature(signedSignature);
    } else {
      signedSignature = signature;
    }
    setGettingSignature(false);
    return signedSignature;
  };

  const allTokens = useMemo(
    () => [
      ...Object.values(ivyBoys),
      ...Object.values(ivyLeague),
      ...Object.values(heedongs),
    ],
    [ivyBoys, ivyLeague, heedongs]
  );

  const tokensInBrigades = useMemo(() => {
    return brigades.reduce((acc: Nft[], brigade: Brigade) => {
      return [...acc, ...brigade.tokens];
    }, []);
  }, [brigades]);

  const isTokenInBrigade = (token: Nft) => {
    return (
      tokensInBrigades.length > 0 &&
      tokensInBrigades.some(
        (brigadeToken: Nft) =>
          brigadeToken &&
          brigadeToken.tokenId === token.tokenId &&
          brigadeToken.contractAddress === token.contractAddress
      )
    );
  };

  const autoGroup = () => {
    const ivyBoysTokens = [...Object.values(ivyBoys)].filter(
      (token) => !isTokenInBrigade(token)
    );
    const ivyLeagueTokens = [...Object.values(ivyLeague)].filter(
      (token) => !isTokenInBrigade(token)
    );
    const heedongsTokens = [...Object.values(heedongs)].filter(
      (token) => !isTokenInBrigade(token) && token.stakedFrom > 0
    );
    setAutoGroupResults(
      autoGroupBrigades(ivyBoysTokens, ivyLeagueTokens, heedongsTokens)
    );
  };

  const fetchBrigades = async () => {
    const brigadeResponse = await heedongApiProvider.getBrigades(
      address as string
    );
    setBrigades(mapNftToBrigades(brigadeResponse, allTokens as Nft[]));
  };

  const deleteBrigade = async (reset = true) => {
    setIsLoading(true);
    if (!existingBrigadeId) return;
    const signedSignature = await getSignature();
    await heedongApiProvider.deleteBrigade(
      address as string,
      signedSignature,
      existingBrigadeId
    );
    if (reset) {
      resetNewBrigadeFormation();
      await fetchBrigades();
      setIsLoading(false);
    }
  };

  const autoGroupingSubmitBrigade = async () => {
    setIsLoading(true);
    try {
      const signedSignature = await getSignature();
      const results = (autoGroupResults as Nft[][]).map((row) => {
        return row.map((token) => ({
          tokenId: token?.tokenId,
          contractAddress: token?.contractAddress,
        }));
      });
      await heedongApiProvider.postBrigade(
        address as string,
        results,
        signedSignature
      );
      await fetchBrigades();
    } catch (err) {
      alert((err as any).response?.data?.message);
      throw new Error((err as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const submitBrigade = async () => {
    try {
      setIsLoading(true);
      // If this is an edit, delete the existing brigade first
      const signedSignature = await getSignature();
      if (existingBrigadeId) {
        await deleteBrigade(false);
      }
      const newBrigade = [] as { tokenId: number; contractAddress: string }[];
      Object.values(newBrigadeFormation).forEach((value) => {
        if (!value) return;
        newBrigade.push({
          tokenId: +value.tokenId,
          contractAddress: value.contractAddress,
        });
      });
      await heedongApiProvider.postBrigade(
        address as string,
        [newBrigade],
        signedSignature
      );
      await fetchBrigades();
      setShowSuccess(true);
    } catch (err) {
      alert((err as any).response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (allTokens.length > 0) {
      fetchBrigades();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTokens]);

  return (
    <Provider
      value={{
        brigades,
        newBrigadeFormation,
        setNewBrigadeFormation,
        resetNewBrigadeFormation,
        showNewBrigadeModal,
        submitBrigade,
        setExistingBrigadeId,
        existingBrigadeId,
        deleteBrigade,
        showSuccess,
        setShowSuccess,
        isLoading,
        autoGroup,
        autoGroupResults,
        setAutoGroupResults,
        isTokenInBrigade,
        autoGroupingSubmitBrigade,
        gettingSignature,
      }}
    >
      {children}
    </Provider>
  );
};

export const useBrigadeContext = () => useContext(BrigadeContext);
