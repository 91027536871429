/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alchemy, Network } from 'alchemy-sdk';

import type { GetNftsForOwnerProps, Nft, NftServiceProvider } from '../types';

class AlchemyProvider implements NftServiceProvider {
  private alchemy: Alchemy;

  constructor() {
    let network = Network.ETH_MAINNET;

    switch (process.env.NEXT_PUBLIC_NETWORK) {
      case 'ETH_GOERLI':
        network = Network.ETH_GOERLI;
        break;

      case 'ETH_SEPOLIA':
        network = Network.ETH_SEPOLIA;
        break;

      default:
        break;
    }

    const settings = {
      apiKey: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY,
      network,
    };
    this.alchemy = new Alchemy(settings);
  }

  async getNftsForOwner({
    address,
    cursor,
    size = 100,
    nftAddresses,
  }: GetNftsForOwnerProps): Promise<Nft[]> {
    const alchemyNftResponse = await this.alchemy.nft.getNftsForOwner(address, {
      pageKey: cursor,
      pageSize: size,
      contractAddresses: nftAddresses,
    });

    const alchemyNfts = alchemyNftResponse.ownedNfts;

    return this.transformNftsData(alchemyNfts);
  }

  // eslint-disable-next-line class-methods-use-this
  transformNftsData(nfts: any): Nft[] {
    return nfts.map((nft: any) => {
      return {
        tokenId: nft.tokenId,
        name: nft.title,
        description: nft.description,
        image: nft.rawMetadata?.image || nft.rawMetadata.image_url,
        externalUrl: '',
        attributes: nft.rawMetadata.attributes,
      };
    });
  }
}

export default new AlchemyProvider();
