import { set } from 'lodash';
import type { FC, ReactNode } from 'react';
import React, { useEffect, useState, useContext } from 'react';
import { useAccount } from 'wagmi';
import { signMessage } from 'wagmi/actions';

import config from '../config';
import constants from '../constants';
import type { Nft } from '../services/nft/types';
import type { Collectible } from '../utils/collectibles';
import { transformCollectiblesResponse } from '../utils/collectibles';

import { useHeeDongNftContext } from './HeeDongNftContext';

type Props = {
  children: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CollectibleContext = React.createContext({} as any);

export const CollectibleProvider: FC<{ children: ReactNode }> = ({
  children,
}: Props) => {
  const { address } = useAccount();
  const { Provider } = CollectibleContext;
  const [walletCollectibles, setWalletCollectibles] = useState<Collectible[]>(
    []
  );
  const [tokenCollectibles, setTokenCollectibles] = useState<Collectible[]>([]);
  const [bones, setBones] = useState<Collectible[]>([]);
  const { heedongs }: { heedongs: Record<string, Nft> } =
    useHeeDongNftContext();

  /**
   * Gets Wallet Collectibles
   */
  const getWalletCollectibles = async () => {
    if (address) {
      try {
        const response = await fetch(
          `${config.apiUrl}/wallet-collectibles?addresses=${address}`
        );
        const walletCollectiblesResponse = await response.json();

        setWalletCollectibles(
          transformCollectiblesResponse(walletCollectiblesResponse)
        );
      } catch (err) {
        console.error(err);
      }
    }
  };

  /**
   * Gets Token Collectibles
   */
  const getTokenCollectibles = async (tokenIds: string[]) => {
    try {
      const response = await fetch(
        `${config.apiUrl}/token-collectibles?tokenIds=${tokenIds.join(',')}`
      );
      const tokenCollectiblesResponse = await response.json();

      setTokenCollectibles(
        transformCollectiblesResponse(tokenCollectiblesResponse)
      );
    } catch (err) {
      console.error(err);
    }
  };

  const findTokenCollectible = (id: number) => {
    return tokenCollectibles.find((collectible) => collectible.id === id);
  };

  const findTokenCollectibles = (ids: number[]) => {
    return tokenCollectibles.filter((collectible) =>
      ids.includes(collectible.id)
    );
  };

  const findWalletCollectible = (id: number) => {
    return walletCollectibles.find((_collectible) => _collectible.id === id);
  };

  /**
   * Update bones
   */
  const updateBones = () => {
    if (tokenCollectibles.length > 0) {
      const boneTokens = findTokenCollectibles(
        Object.values(constants.bones).map((bone) => bone.id)
      );

      setBones(boneTokens);
    } else {
      setBones([]);
    }
  };

  /**
   * Reveals Gift Bones
   */
  const revealGiftBones = async () => {
    // Signs a message
    const signature = await signMessage({
      message: address as string,
    });

    // Reveal the bone
    await fetch(`${config.apiUrl}/wallet-collectibles/reveal`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ address, signature, tokenId: 1 }),
    });
  };

  useEffect(() => {
    updateBones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenCollectibles]);

  useEffect(() => {
    if (Object.values(heedongs).length > 0) {
      getTokenCollectibles(
        Object.values(heedongs).map((heedong: Nft) =>
          heedong.tokenId.toString()
        )
      );
    } else {
      setTokenCollectibles([]);
    }
  }, [heedongs]);

  return (
    <Provider
      value={{
        bones,
        findTokenCollectible,
        findTokenCollectibles,
        findWalletCollectible,
        walletCollectibles,
        tokenCollectibles,
        getWalletCollectibles,
        getTokenCollectibles,
        revealGiftBones,
      }}
    >
      {children}
    </Provider>
  );
};

export const useCollectibleContext = () => useContext(CollectibleContext);
