// import type { StyleConfig } from '@chakra-ui/react';

export const styles = {
  global: {
    // styles for the `body`
    body: {
      bg: '#003650',
    },
  },
  textStyles: {
    machine: {
      fontFamily: 'Press Start 2P',
      textTransform: 'uppercase',
    },
  },
};
