import { getDefaultConfig } from 'connectkit';
import { createConfig } from 'wagmi';
import { goerli, sepolia, mainnet, hardhat } from 'wagmi/chains';

let chains = [];
switch (process.env.NEXT_PUBLIC_NETWORK) {
  case 'ETH_GOERLI':
    chains = [goerli];
    break;

  case 'ETH_SEPOLIA':
    chains = [sepolia];
    break;

  case 'ETH_MAINNET':
    chains = [mainnet];
    break;

  default:
    chains = [hardhat, goerli, sepolia, mainnet];
    break;
}

export const connectKitClient = createConfig(
  getDefaultConfig({
    appName: 'HeeDong',
    chains,
    walletConnectProjectId: process.env
      .NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID as string,
    alchemyId: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY,
  })
);
