import dayjs from 'dayjs';

export type Collectible = {
  id: number;
  name: string;
  description: string;
  image_url: string;
  animation_url: string;
  quantity?: number;
};

export type Collectibles = {
  [key: string]: Collectible;
};

type CollectiblesForOwner = {
  [key: string]: {
    [key: string]: {
      quantity: number;
      collectible_id: number;
      wallet: string;
    };
  };
};

export type CollectibleResponse = {
  token_collectibles: Collectibles;
  token_collectibles_for_tokens?: CollectiblesForOwner;
  wallet_collectibles?: Collectibles;
  wallet_collectibles_for_wallets?: CollectiblesForOwner;
};

export const transformCollectiblesResponse = (
  response: CollectibleResponse
) => {
  let collectibles: Collectibles = {};
  let collectiblesForOwner: CollectiblesForOwner = {};
  if (response.token_collectibles && response.token_collectibles_for_tokens) {
    collectibles = response.token_collectibles;
    collectiblesForOwner = response.token_collectibles_for_tokens;
  } else if (
    response.wallet_collectibles &&
    response.wallet_collectibles_for_wallets
  ) {
    collectibles = response.wallet_collectibles;
    collectiblesForOwner = response.wallet_collectibles_for_wallets;
  }

  const collectiblesArray = Object.values(collectibles);

  // Highly inefficient loop O(n2)
  return collectiblesArray.map((collectible) => {
    const collectibleWithQuantity = {
      ...collectible,
      quantity: 0,
    };
    Object.keys(collectiblesForOwner).forEach((addressOrTokenId) => {
      Object.keys(collectiblesForOwner[addressOrTokenId]).forEach(
        (collectibleId) => {
          if (collectibleId === String(collectibleWithQuantity.id)) {
            collectibleWithQuantity.quantity +=
              collectiblesForOwner[addressOrTokenId][collectibleId].quantity;
          }
        }
      );
    });
    return collectibleWithQuantity;
  });

  /*
  const addressOrTokenId = Object.keys(collectiblesForOwner)[0];

  return collectiblesArray.map((collectible) => ({
    ...collectible,
    quantity:
      collectiblesForOwner[addressOrTokenId][collectible.id]?.quantity || 0,
  }));
  */
};

export const calculateNextBoneDrop = (initialBoneDrop: string) => {
  let nextBoneDrop = dayjs(initialBoneDrop);

  while (nextBoneDrop.isBefore(dayjs())) {
    nextBoneDrop = nextBoneDrop.add(7, 'day');
  }

  return nextBoneDrop.toISOString();
};
