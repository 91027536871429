/* eslint-disable react/jsx-props-no-spreading */
import * as react from '@chakra-ui/react';
import { ConnectKitProvider } from 'connectkit';
import { AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { useEffect } from 'react';
import { WagmiConfig } from 'wagmi';

import defaultSEOConfig from '../../next-seo.config';
import { Chakra } from '@lib/components/Chakra';
import { connectKitClient } from '@lib/ethereum/ConnectKit';

import '@lib/styles/globals.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';
import AppProviders from './_providers';

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const { colorMode, toggleColorMode } = react.useColorMode();

  //  Force darkmode
  useEffect(() => {
    if (colorMode === 'light') {
      toggleColorMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WagmiConfig config={connectKitClient}>
      <ConnectKitProvider
        options={{
          walletConnectCTA: 'modal',
          hideQuestionMarkCTA: true,
          hideNoWalletCTA: true,
        }}
        customTheme={{
          '--ck-font-family': '"Montserrat", sans-serif',
        }}
      >
        <AppProviders>
          <Chakra>
            <Head>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
              />
            </Head>
            <DefaultSeo {...defaultSEOConfig} />

            <AnimatePresence
              mode="wait"
              onExitComplete={() => window.scrollTo(0, 0)}
            >
              <GoogleAnalytics trackPageViews />
              <Component {...pageProps} key={router.pathname} />
            </AnimatePresence>
          </Chakra>
        </AppProviders>
      </ConnectKitProvider>
    </WagmiConfig>
  );
};

export default App;
