import config from '../config';
import type { Brigade, BrigadeFormation, Nft } from '../services/nft/types';

export const transformBrigadeToBrigadeFormation = (
  brigade: Brigade
): BrigadeFormation => {
  const heedong = brigade.tokens.find(
    (token) => token.contractAddress === config.contractAddresses.heedong
  ) as Nft;
  const ivyBoy = brigade.tokens.find(
    (token) => token.contractAddress === config.contractAddresses.ivyBoys
  ) as Nft;
  const ivyLeague = brigade.tokens.find(
    (token) => token.contractAddress === config.contractAddresses.ivyLeague
  ) as Nft;

  return {
    heedong,
    ivyBoy,
    ivyLeague,
  };
};

export const mapNftToBrigades = (brigadeResponse: any[], tokens: Nft[]) => {
  if (!brigadeResponse) return [];
  return brigadeResponse.map((brigade) => {
    return {
      ...brigade,
      tokens: brigade.tokens.map(
        (token: { tokenId: number; contractAddress: string }) => {
          return tokens.find((t) => {
            return (
              +t.tokenId === +token.tokenId &&
              t.contractAddress === token.contractAddress
            );
          });
        }
      ),
    };
  });
};

export const splitCamelCaseToString = (s: string) => {
  return s.split(/(?=[A-Z])/).join(' ');
};

export const autoGroupBrigades = (
  ivyBoys: Nft[],
  ivyLeague: Nft[],
  heedongs: Nft[]
) => {
  const output = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (heedongs.length === 0) break;
    if (ivyLeague.length > 0 && ivyBoys.length > 0) {
      output.push([heedongs.pop(), ivyLeague.pop(), ivyBoys.pop()]);
    } else if (ivyBoys.length > 0) {
      output.push([heedongs.pop(), ivyBoys.pop()]);
    } else {
      output.push([heedongs.pop()]);
    }
  }
  return output;
};
