import type { DeepPartial, Theme } from '@chakra-ui/react';

/** extend additional color here */
const extendedColors = {
  background: {
    100: '#EAE9DA', //  Beige
    200: '#003650',
    300: '#FFCA13', // Yellow
    400: '#F85F32', // Red
    500: '#29A278', // Green
    600: '#F95F34', // Orange
    700: '#38A7D9', // Light Blue
    800: '#003650', // Dark blue background
    900: '#003650',
  },
};

/** override chakra colors here */
const overridenChakraColors: DeepPartial<Theme['colors']> = {};

export const colors = {
  ...overridenChakraColors,
  ...extendedColors,
};
