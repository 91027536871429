import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import type { Nft } from '@services/nft/types';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few secs',
    m: 'a min',
    mm: '%d mins',
    h: 'an hr',
    hh: '%d hrs',
    d: 'a day',
    dd: '%d days',
    M: 'a mth',
    MM: '%d mths',
    y: 'a yr',
    yy: '%d yrs',
  },
});

const sortingOrder = {
  LEAGUE: 1,
  PAIR: 2,
  SOLO: 3,
};

export const getPhase = (nft: Nft | null) => {
  return Number(
    nft?.attributes?.find((attribute) => attribute.trait_type === 'Phase')
      ?.value ?? 0
  );
};

export const countDown = (unix: number) => {
  const total = dayjs(unix).diff(dayjs(), 'seconds');
  const day = Math.floor(total / 86400);
  const hour = Math.floor((total % 86400) / 3600);
  const minute = Math.floor((total % 3600) / 60);
  const second = Math.floor(total % 60);

  const dayText = day ? `${day}d : ` : '';
  const hourText = hour ? ` ${hour}h : ` : '';
  const minuteText = minute ? `${minute}m` : '';
  const secondText = second ? ` : ${second}s` : '';

  return `${dayText}${hourText}${minuteText}${secondText}`;
};

export const stakingCountDown = (nft: Nft, nextPhaseDuration: number) => {
  const nextPhase = dayjs
    .unix(nft.stakedFrom)
    .add(nextPhaseDuration / 1000, 'seconds');
  const total = nextPhase.diff(dayjs(), 'seconds');
  const day = Math.floor(total / 86400);
  const hour = Math.floor((total % 86400) / 3600);
  const minute = Math.floor((total % 3600) / 60);

  const dayText = day ? `${day}d : ` : '';
  const hourText = hour ? ` ${hour}hr : ` : '';
  const minuteText = minute ? ` ${minute}min` : '';

  return `${dayText}${hourText}${minuteText}`;
};

export const stakedDuration = (nft: Nft) =>
  nft?.stakedFrom === 0 ? 0 : dayjs().diff(dayjs.unix(nft.stakedFrom));

export const stakedDurationText = (nft: Nft) => {
  return nft?.stakedFrom
    ? `${dayjs().diff(dayjs.unix(nft?.stakedFrom), 'days')} days`
    : null;
};

export const sortDisplay = (nft1: Nft, nft2: Nft) => {
  if (!nft1.brigade) {
    return 1;
  }
  if (!nft2.brigade) {
    return -1;
  }
  return sortingOrder[nft1.brigade.type] - sortingOrder[nft2.brigade.type];
};

export const getBlindbox = (heedong: Nft | undefined) => {
  return heedong?.attributes?.find((attr) => attr.trait_type === 'Blindbox');
};
