import { readContract } from '@wagmi/core';
import type { Axios } from 'axios';
import axios from 'axios';

import type {
  Brigade,
  GetNftsForOwnerProps,
  Nft,
  NftServiceProvider,
} from '../types';

class HeeDongApiProvider implements NftServiceProvider {
  private baseApiUrl: string;

  private api: Axios;

  constructor() {
    if (process.env.NEXT_PUBLIC_BASE_URL) {
      this.baseApiUrl = process.env.NEXT_PUBLIC_BASE_URL;
      this.api = axios.create({ baseURL: `${this.baseApiUrl}` });
    } else {
      throw new Error('No Base URL provided');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getNftsForOwner({
    address,
    nftAddresses,
  }: GetNftsForOwnerProps): Promise<Nft[]> {
    // Gets the owner's nft ids
    const ids = await readContract({
      address: nftAddresses[0] as `0x${string}`,
      abi: [
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'tokensOfOwner',
          outputs: [
            {
              internalType: 'uint256[]',
              name: '',
              type: 'uint256[]',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ] as const,
      functionName: 'tokensOfOwner',
      args: [address as `0x${string}`],
    });

    const nfts = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const id of ids) {
      nfts.push(this.getNftById(id.toString()));
    }

    return Promise.all(nfts);
  }

  async claimBlindbox(
    tokenIds: number[],
    address: string,
    signature: string
  ): Promise<void> {
    await this.api.post(
      `${this.baseApiUrl}/token-collectibles/heedong-blindbox/claim`,
      {
        tokenIds,
        address,
        signature,
      }
    );
  }

  async revealBlindbox(tokenIds: number[], address: string, signature: string) {
    return this.api.post<Record<string, 5 | 6 | 7 | 8 | 9 | 10 | 11>>(
      `token-collectibles/heedong-blindbox/reveal`,
      {
        tokenIds,
        address,
        signature,
      }
    );
  }

  async rerollBlindbox(tokenIds: number[], address: string, signature: string) {
    const { data } = await this.api.post<
      Record<string, 5 | 6 | 7 | 8 | 9 | 10 | 11>
    >(`token-collectibles/heedong-blindbox/reroll`, {
      tokenIds,
      address,
      signature,
    });
    return data;
  }

  async getRerollCredits(address: string) {
    const { data } = await this.api.get<{ address: string; rerolls: number }>(
      `token-collectibles/heedong-blindbox/reroll-credits/${address}`
    );
    return data.rerolls;
  }

  async postRerollCredits(
    address: string,
    signature: string,
    bones: { tokenId: number; boneId: number; quantity: number }[]
  ) {
    return this.api.post(`token-collectibles/heedong-blindbox/reroll-credits`, {
      address,
      signature,
      bones,
    });
  }

  async getNftById(id: string, collection = 'heedong'): Promise<Nft> {
    const response = await fetch(
      `${this.baseApiUrl}/nft-metadata/${collection}/${id}`
    );
    const data = await response.json();

    return {
      tokenId: data.tokenId,
      name: data.name,
      description: data.description,
      image: data.image,
      animation_url: data.animation_url,
      externalUrl: '',
      attributes: data.attributes,
      stakedFrom: 0,
      contractAddress: data.contractAddress,
    };
  }

  getBrigades = async (address: string): Promise<Brigade[]> => {
    const response = await fetch(
      `${this.baseApiUrl}/brigade?address=${address}`
    );
    return response.json();
  };

  postBrigade = async (
    address: string,
    brigades: { tokenId: number; contractAddress: string }[][],
    signature: string
  ) => {
    await axios.post(`${this.baseApiUrl}/brigade`, {
      address,
      signature,
      brigades,
    });
  };

  deleteBrigade = async (
    address: string,
    signature: string,
    brigadeId: number
  ) => {
    await axios.delete(
      `${this.baseApiUrl}/brigade?address=${address}&signature=${signature}&brigadeId=${brigadeId}`
    );
  };

  claimVinyl = async (txHash: string) => {
    return axios.post(
      `${this.baseApiUrl}/token-collectibles/heedong-blindbox/claim-vinyl`,
      { txHash }
    );
  };

  fetchPromoCodes = async (tokenIds: number[]) => {
    const { data } = await axios.get(
      `${
        this.baseApiUrl
      }/token-collectibles/heedong-blindbox/discount-codes?tokenIds=${tokenIds.join(
        ','
      )}`
    );
    return data;
  };
}

export default new HeeDongApiProvider();
