import { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import React, { useEffect, useState, useContext } from 'react';
import { useAccount } from 'wagmi';

import config from '@config';

type Props = {
  children: ReactNode;
};

type FeatureFlagContextType = {
  [key: string]: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FeatureFlagContext = React.createContext({} as FeatureFlagContextType);

export const FeatureFlagProvider: FC<{ children: ReactNode }> = ({
  children,
}: Props) => {
  const { query } = useRouter();
  const { address } = useAccount();
  const { Provider } = FeatureFlagContext;

  // Feature Flags
  const [enableStars, setEnableStars] = useState<boolean>(true);
  const [enableLeaderboard, setEnableLeaderboard] = useState<boolean>(false);

  useEffect(() => {
    setEnableLeaderboard(
      query.enableLeaderboard === 'true' ||
      config.allowedAddresses.includes(address || '')
    );
  }, [address, query]);

  return (
    <Provider
      value={{
        enableStars,
        enableLeaderboard,
      }}
    >
      {children}
    </Provider>
  );
};

export const useFeatureFlagContext = () => useContext(FeatureFlagContext);
