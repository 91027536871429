import type { FC, ReactNode } from 'react';
import React, { useMemo, useState, useContext } from 'react';
import { useAccount } from 'wagmi';

import { getBlindbox } from '../utils/nft';
import nftService from '@services/nft/nft.service';
import type { Nft, NftAttribute } from '@services/nft/types';

type Props = {
  children: ReactNode;
};

type HeeDongNftContextType = {
  isFetchingHeedongs: boolean;
  fetchHeeDongs: () => Promise<void>;
  updateHeeDong: (tokenId: number, newHeedong: Nft) => Promise<void>;
  updateHeeDongs: (params: Record<number, Nft>) => void;
  heedongs: Record<string, Nft>;
  growHeeDong: (
    id: number,
    signature: string
  ) => Promise<{ success: boolean; token: Nft }>;
  isGrowing: boolean;
  setIsGrowing: React.Dispatch<React.SetStateAction<boolean>>;
  blindbox: (NftAttribute & { heedongId: number })[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HeeDongNftContext = React.createContext({} as HeeDongNftContextType);

export const HeeDongNftProvider: FC<{ children: ReactNode }> = ({
  children,
}: Props) => {
  const { address } = useAccount();
  const { Provider } = HeeDongNftContext;
  const [isFetchingHeedongs, setIsFetchingHeedongs] = useState<boolean>(false);
  const [isGrowing, setIsGrowing] = useState(false);
  const [heedongs, setHeedongs] = useState<Record<string, Nft>>({});
  const baseApiUrl: string = process.env.NEXT_PUBLIC_BASE_URL as string;

  /**
   * Gets heedongs
   */
  const fetchHeeDongs = async () => {
    if (address) {
      setIsFetchingHeedongs(true);
      let nfts = await nftService.getNftsForOwner(address);
      nfts = await nftService.updateStakingStatus(nfts, address);

      setHeedongs(nfts);
      setIsFetchingHeedongs(false);
    }
  };

  /**
   * updates a single heedong
   * @param id number
   */
  const updateHeeDong = async (tokenId: number, newHeedong: Nft) => {
    const nfts = await nftService.updateStakingStatus(
      {
        [tokenId]: newHeedong,
      },
      address as string
    );
    setHeedongs((prevHeedongs) => {
      return {
        ...prevHeedongs,
        ...nfts,
      };
    });
  };

  /**
   * updates multiple heedongs
   * @param ids number[]
   */
  const updateHeeDongs = async (params: Record<number, Nft>) => {
    const nfts = await nftService.updateStakingStatus(
      params,
      address as string
    );
    setHeedongs((prevHeedongs) => {
      return {
        ...prevHeedongs,
        ...nfts,
      };
    });
  };

  /**
   * Grow Heedong
   */
  const growHeeDong = async (
    id: number,
    signature: string
  ): Promise<{ success: boolean; token: Nft }> => {
    const response = await fetch(
      `${baseApiUrl}/collections/heedong/${id}/grow`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          signature,
          address,
        }),
      }
    );
    return response.json();
  };

  const blindbox = useMemo(
    () =>
      Object.values(heedongs)
        .map((heedong) => ({
          ...(getBlindbox(heedong) as NftAttribute),
          heedongId: heedong.tokenId,
        }))
        .filter((attr) => attr?.image_url),
    [heedongs]
  );

  return (
    <Provider
      value={{
        isFetchingHeedongs,
        fetchHeeDongs,
        updateHeeDong,
        updateHeeDongs,
        heedongs,
        growHeeDong,
        isGrowing,
        setIsGrowing,
        blindbox,
      }}
    >
      {children}
    </Provider>
  );
};

export const useHeeDongNftContext = () => useContext(HeeDongNftContext);
