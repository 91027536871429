import AlchemyProvider from './alchemy/alchemy.provider';
import type { Nft, NftServiceProvider } from './types';

class AlchemyService {
  private NftProvider: NftServiceProvider;

  constructor() {
    this.NftProvider = AlchemyProvider;
  }

  async getNftsForOwner(
    nftContractAddress: string,
    ownerAddress: string
  ): Promise<Record<string, Nft>> {
    const nfts = await this.NftProvider.getNftsForOwner({
      address: ownerAddress,
      nftAddresses: [nftContractAddress],
    });

    const nftsObject: Record<string, Nft> = {};
    nfts.forEach((nft) => {
      nftsObject[nft.tokenId] = {
        ...nft,
        tokenId: +nft.tokenId,
        contractAddress: nftContractAddress,
      };
    });

    return nftsObject;
  }
}

export default new AlchemyService();
